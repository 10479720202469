import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function AboutUs() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="flex flex-col lg:flex-row items-center relative m-4 lg:m-20 gap-8 my-10">
      <div
        className="flex-1 relative"
        data-aos="fade-right"
        data-aos-delay="200"
      >
        <div className="responsive-div border-secondary">
          <p></p>
        </div>
        <div className="translate-x-2 translate-y-2 lg:absolute lg:top-0 lg:left-0 transform lg:translate-x-8 lg:translate-y-8 z-20 rounded-xl">
          {/* <img
            src="https://res.cloudinary.com/dregpcfhh/image/upload/v1720155527/Nivez_Bridal/Homepage/about-1_heb7cj.jpg"
            alt="about"
            style={{
              height: '600px',
              width: 'auto',
              objectFit: 'cover',
            }}
            loading="lazy"
            lazy="true"
            onLoad={handleImageLoad}
            className="rounded-xl"
          /> */}
          <LazyLoadImage
            className="rounded-xl"
            alt="about"
            effect="opacity"
            src="https://res.cloudinary.com/dregpcfhh/image/upload/v1720155527/Nivez_Bridal/Homepage/about-1_heb7cj.jpg"
            placeholderSrc="https://via.placeholder.com/600x400"
            style={{
              height: '600px',
              width: 'auto',
              objectFit: 'cover',
            }}
            wrapperProps={{
              style: { transitionDelay: '200ms' },
            }}
          />
        </div>
      </div>
      <div className="flex-1 p-4 flex flex-col items-center">
        <h1
          className="text-3xl lg:text-5xl font-bold font-gwendolyn text-secondary"
          data-aos="fade-left"
          data-aos-delay="200"
        >
          Who are we
        </h1>
        <h2
          className="text-4xl lg:text-7xl font-galdeano text-primary mt-4 lg:mt-8"
          data-aos="fade-left"
          data-aos-delay="300"
        >
          The royal treatment for the hands of a queen.
        </h2>
        <div
          className="text-base lg:text-xl text-justify mt-4 lg:mt-8"
          data-aos="fade-left"
          data-aos-delay="400"
        >
          Welcome to Nivez Bridal! I,{' '}
          <i>
            <b>Nivethiga</b>
          </i>{' '}
          started my journey as a makeup artist in 2019. Previously, I have 8
          years of working experience in a private bank and have completed my
          banking degrees during this period. As a hobby, I enjoyed doing make
          up on my friends and they got surprised by their elevated beauty.
          Since I received lots of positive comments frequently from my friends,
          I decided to make it as my part time profession while working in the
          bank. During this period I realized that my true passion is helping
          women look and feel more beautiful. Then I took a brave decision to
          chase my passion hence I quit my bank job and started my own business{' '}
          <b>“Nivez Bridal”</b>. To make myself qualified for this profession, I
          completed makeup relevant courses and have gained lots of hands on
          practices with the help of my friends and family members.
          <br />
          <br /> I want to make my bride more comfortable thus I never did
          anything over their preferrences. Also I never imposed my interests or
          my talents on them. My mission is to always try to make them feel more
          confident by enhancing their natural beauty as they wish on their big
          day.
          <br />
          <br /> To date I have never participated in any bridal shows or
          magazine shows to promote my business. Because I believe that “word of
          mouth” is the best marketing way in the world. My customers are the
          reason for my progression. They trust on me and give full freedom to
          do my job flawlessly. I am always grateful for them for believing on
          me and sharing their priceless moments with me constantly.
          <br />
          <br /> As a next step in my career, I have successfully established my
          business as <b>“Nivez Bridal & Academy (Pvt) Ltd.”</b> since May 2024. We
          have qualified staff and we offer all beauty related services here
          such as beauty therapy, hair dressing, nails and many more. We provide
          100% genuine services and we ensure that we never compromise with
          customer satisfaction. Every woman is unique and deserves a
          distinguishing, and customized look.
          <br />
          <br />{' '}
          <p className="text-2xl lg:text-2xl font-bold font-galdeano text-primary mt-4 lg:mt-8">
            “Look Elegant and Feel Confident”
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;

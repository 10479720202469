/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../logo/logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faFacebookF,
  faInstagram,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import MapComponent from '../MapCoponent';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="bg-gray-800 text-white py-8">
      <div>
        <MapComponent />
      </div>
      <div className="container mx-auto px-4 py-8 md:px-20 lg:px-20">
        <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 gap-4">
          <div className="col-span-1 md:col-span-3">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                navigate('/');
              }}
            >
              <Logo sx={{ mr: 4 }} />
            </div>
            <p className="text-gray-200">
              No. 127/2B, Station Road,
              <br />
              Vairavapuliyankulam,
              <br />
              Vavuniya, Sri Lanka.
              <br />
              Phone: +94 77 5161518
              <br />
              Landline: 024 222 0556
              <br />
            </p>
            <div className="mt-4 flex space-x-4">
              <a
                href="https://www.facebook.com/profile.php?id=100063705994507"
                className="text-white"
              >
                <FontAwesomeIcon icon={faFacebook} size="2xl" />
              </a>
              <a
                href="https://web.facebook.com/profile.php?id=61559601130246"
                className="text-white"
              >
                <FontAwesomeIcon icon={faFacebookF} size="2xl" />
              </a>
              <a
                href="https://www.instagram.com/nivez_bridal/"
                className="text-white"
              >
                <FontAwesomeIcon icon={faInstagram} size="2xl" />
              </a>
              <a
                href="https://www.tiktok.com/@nive_sachin"
                className="text-white"
              >
                <FontAwesomeIcon icon={faTiktok} size="2xl" />
              </a>
            </div>
          </div>

          <div className="col-span-1"></div>

          <div className="col-span-1 pt-8">
            <h3 className="text-lg font-semibold mb-2 text-white">
              Quick Links
            </h3>
            <ul className="list-none space-y-2">
              <li>
                <a href="/" className="text-gray-200 hover:text-white">
                  Home
                </a>
              </li>
              <li>
                <a href="/#about-us" className="text-gray-200 hover:text-white">
                  About Us
                </a>
              </li>
              <li>
                <a href="/#services" className="text-gray-200 hover:text-white">
                  Services
                </a>
              </li>
              <li>
                <a href="/#gallery" className="text-gray-200 hover:text-white">
                  Gallery
                </a>
              </li>
              <li>
                <a
                  href="/#testimonials"
                  className="text-gray-200 hover:text-white"
                >
                  Testimonials
                </a>
              </li>
              <li>
                <a
                  href="/contact-us"
                  className="text-gray-200 hover:text-white"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>

          {/* add google maps */}
        </div>
      </div>

      <div className="mt-8 text-center">
        <p>
          Copyright © 2024 Nivez Bridal & Academy (Pvt) Ltd. Designed By{' '}
          <a
            href="https://www.deviweb.com"
            className="text-blue-400 hover:underline"
          >
            Devi Web
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ServicePage from './pages/ServicePage';
import ContactUs from './pages/ContactUs';
import GalleryPage from './pages/GalleryPage';
import ScrollToTop from './components/ScrollToTop';

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-M9EB2N0C02';
    script.async = true;
    document.head.appendChild(script);

    const scriptInit = document.createElement('script');
    scriptInit.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-M9EB2N0C02');
    `;
    document.head.appendChild(scriptInit);
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicePage />}>
          <Route path="makeup" />
          <Route path="hair" />
          <Route path="skin" />
          <Route path="nails" />
          <Route path="combo" />
        </Route>
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/gallery" element={<GalleryPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

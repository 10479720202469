import React, { useRef, useState } from 'react';
import Header from '../components/header/Header';
import { Footer } from '../components/footer';
import emailjs from '@emailjs/browser';

export default function ContactUs() {
  const formRef = useRef();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });
  const [errors, setErrors] = useState({});

  const {
    REACT_APP_EMAILJS_SERVICE_ID,
    REACT_APP_EMAILJS_TEMPLATE_ID,
    REACT_APP_EMAILJS_PUBLIC_KEY,
  } = process.env;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};
    const { firstName, lastName, email, phone, subject, message } = formData;

    if (!firstName.trim()) newErrors.firstName = 'First Name is required';
    if (!lastName.trim()) newErrors.lastName = 'Last Name is required';
    if (!email.trim()) newErrors.email = 'Email is required';
    if (!phone.trim() || phone.length !== 10 || isNaN(phone))
      newErrors.phone = 'Valid phone is required';
    if (!subject) newErrors.subject = 'Subject is required';
    if (!message.trim()) newErrors.message = 'Message is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await emailjs.sendForm(
        REACT_APP_EMAILJS_SERVICE_ID,
        REACT_APP_EMAILJS_TEMPLATE_ID,
        formRef.current,
        REACT_APP_EMAILJS_PUBLIC_KEY
      );
      alert('Form submitted successfully');
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      });
    } catch (error) {
      alert('Form submission failed');
      console.error(error);
    }
  };

  return (
    <div>
      <Header />
      <div className="container mx-auto md:px-44 px-4 py-8">
        <h1 className="text-3xl font-bold mb-8">Contact Us</h1>
        <form ref={formRef} onSubmit={sendEmail} className="space-y-4">
          {['firstName', 'lastName', 'email', 'phone'].map((field) => (
            <div key={field}>
              <label className="block text-gray-700 capitalize">
                {field.replace(/([A-Z])/g, ' $1')}
              </label>
              <input
                type="text"
                name={field}
                value={formData[field]}
                onChange={handleChange}
                className="px-3 py-2 rounded-md border w-full"
              />
              {errors[field] && (
                <span className="text-red-500 text-sm">{errors[field]}</span>
              )}
            </div>
          ))}
          <div>
            <label className="block text-gray-700">Subject</label>
            <select
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="px-3 py-2 w-full rounded-md border"
            >
              <option value="">Select a Subject</option>
              <option value="General Inquiry">General Inquiry</option>
              <option value="Technical Support">Ask Price Details</option>
              <option value="Feedback">Feedback</option>
            </select>
            {errors.subject && (
              <span className="text-red-500 text-sm">{errors.subject}</span>
            )}
          </div>
          <div>
            <label className="block text-gray-700">Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="px-3 py-2 rounded-md border w-full h-32"
            />
            {errors.message && (
              <span className="text-red-500 text-sm">{errors.message}</span>
            )}
          </div>
          <button
            type="submit"
            className="bg-secondary w-full text-white px-4 py-2 rounded-md hover:bg-primary"
          >
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
}

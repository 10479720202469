import React from 'react';

const MapComponent = () => {
  return (
    <div style={{ height: '400px', width: '100%' }}>
      <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3944.3665924911287!2d80.48781757480763!3d8.756971651047208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3afc159376888d39%3A0xb580bddc3bd194fe!2sNivez%20Bridal%20%26%20Academy!5e0!3m2!1sen!2slk!4v1726721108!5m2!1sen!2slk"
        width="100%"
        height="400"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      />
    </div>
  );
};

export default MapComponent;
